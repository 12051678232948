<template>
  <div class="content ui form">
    <div class="style-form-header">
      <h3>{{ t('user.edit.user_styles.title') }}</h3>
      <a @click="isFormModified()" class="back-link">
        <i class="left arrow icon"></i>
        {{ t('user.edit.user_styles.back_styles') }}
      </a>
    </div>
    <div class="style-form-top">
      <div class="style-form-top-items">
        <div class="style-form-top-items-inline">
          <div class="required">
            <label class="form label">{{ t('user.edit.user_styles.style_name') }}</label>
            <span>*</span>
          </div>
          <input v-model="style.name_style" :placeholder="t('user.edit.user_styles.style_name_placeholder')" class="light input styleInput" name="name" id="nameInput">
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label" for="color">{{ t('user.edit.user_styles.text_color') }}</label> 
          <input v-model="style.primary_color" name="color" style="display: none;">
          <el-color-picker :value="primaryColorStyle()" @input="FormModified = true" @change="getPrimaryColor($event)" size="small" color-format="hex"></el-color-picker>
          <!-- Add show-alpha for opacity -->
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label" for="spacing">{{ t('user.edit.user_styles.character_spacing') }}</label>
          <input v-model="style.spacing" type="number" class="light input styleInput" name="spacing">
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label" for="spacing">{{ t('user.edit.user_styles.border_style') }}</label>
          <div class="style-form-top-items-inline-border_radio">
            <label class="form light label" for="outline">{{ t('user.edit.user_styles.border') }}</label>
            <div class="ui radio">
              <input type="radio" id="outline" v-model="style.border_style" value="1" class="styleInput">
              <div class="authot-radio"></div>
            </div>
            <label class="form light label" for="opaqueBox">{{ t('user.edit.user_styles.opaque_box') }}</label>
            <div class="ui radio">
              <input type="radio" id="opaqueBox" v-model="style.border_style" value="3" class="styleInput">
              <div class="authot-radio"></div>
            </div>
          </div>
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label" for="outlineColor">{{ t('user.edit.user_styles.border_color') }}</label>
          <input v-model="style.outline_color" name="outlineColor" style="display: none;">
          <el-color-picker :value="outlineColorStyle()" @input="FormModified = true" @change="getOutlineColor($event)" size="small" color-format="hex"></el-color-picker>
          <!-- Add show-alpha for opacity -->
        </div>
        <!-- <div class="style-form-top-items-inline">
          <label class="form light label" for="outlineSize">Épaisseur du contour</label>
          <input disabled type="number" id="outlineSize" v-model="style.outline" class="light input">
        </div> -->
        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.cpl') }}</label>
          <input type="number" min="0" v-model="style.cpl" class="light input styleInput">
        </div>
        
      </div>
      <div class="style-form-top-items">
        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.font') }}</label>
          <div class="ui search selection dropdown font-dropdown styleInput">
            <input type="hidden" name="font-dropdown">
            <i class="dropdown icon"></i>
            <div class="default text">{{ style.font_name }}</div>
            <div class="menu">
              <div class="item" v-for="(font, i) in fontsList" :key="i" :data-id="font.id" :data-value="font.name">{{ font.name }}</div>
            </div>
          </div>
        </div>

        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.font_size') }}</label>
          <input v-model="style.font_size" type="number" class="light input styleInput">
        </div>              
        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.text_style') }}</label>
          <div>
            <div class="ui checkbox style bold">
              <input type="checkbox" v-model="style.bold" true-value="-1" false-value="0" class="styleInput">
              <label></label>
            </div>
            <div class="ui checkbox style italic">
              <input type="checkbox" v-model="style.italic" true-value="-1" false-value="0" class="styleInput">
              <label></label>
            </div>

            <div class="ui checkbox style underline">
              <input type="checkbox" v-model="style.underline" true-value="-1" false-value="0" class="styleInput">
              <label></label>
            </div>
            
            <div class="ui checkbox style line">
              <input type="checkbox" v-model="style.strike_out" true-value="-1" false-value="0" class="styleInput">
              <label></label>
            </div>
          </div>
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.bloc_position') }}</label>
          <div class="style-form-top-items-square">
            <div class="position-line">
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="7" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="8" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="9" class="styleInput">
                <div class="square-radio"></div>
              </div>
            </div>
            <div class="position-line">
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="4" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="5" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="6" class="styleInput">
                <div class="square-radio"></div>
              </div>
            </div>
            <div class="position-line">
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="1" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="2" class="styleInput">
                <div class="square-radio"></div>
              </div>
              <div class="ui radio">
                <input type="radio" v-model="style.alignment" value="3" class="styleInput">
                <div class="square-radio"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="style-form-top-items-inline">
          <label class="form light label">Couleur de l'ombre</label>
          <input v-model="style.background_color" style="display: none;">
          <el-color-picker disabled :value="backgroundColorStyle()" @change="getShadowColor($event)" size="small" color-format="hex"></el-color-picker>
        </div> -->
        <!-- <div class="style-form-top-items-inline">
          <label class="form light label" for="shadowSize">Position de l'ombre</label>
          <input disabled type="number" class="light input" id="shadowSize" v-model="style.shadow">
        </div> -->
        <div class="style-form-top-items-inline style-checkboxes">
          <div class="ui checkbox full-count">
            <input type="checkbox" id="fullCount" true-value="true" false-value="false" v-model="style.full_count_cpl" class="styleInput">
            <label class="label-full-count" for="fullCount">{{ t('user.edit.user_styles.space_punctuation') }}</label>
          </div>
          <div class="ui checkbox full-count">
            <input type="checkbox" id="alertTimecode" true-value="true" false-value="false" v-model="style.timecode_alert" class="styleInput">
            <label class="label-full-count" for="alertTimecode">{{ t('user.edit.user_styles.alert_timecode') }}</label>
          </div>
        </div>
        <div class="style-form-top-items-inline">
          <label class="form light label">{{ t('user.edit.user_styles.cps') }}</label>
          <input type="number" min="0" v-model="style.cps" class="light input styleInput">
        </div>
      </div>
    </div>
    <div class="style-form-margins">
      <div>Marges</div>
      <div class="style-form-margins-inputs">
        <div class="style-form-margins-inputs-input">
          <label class="form light label" for="marginLeft">Gauche</label>
          <input type="number" id="marginLeft" min="0" v-model="style.margin_l" class="light input">
        </div>
        <div class="style-form-margins-inputs-input">
          <label class="form light label" for="marginRight">Droite</label>
          <input type="number" id="marginRight" min="0" v-model="style.margin_r" class="light input">
        </div>
        <div class="style-form-margins-inputs-input">
          <label class="form light label" for="marginVertical">Verticale</label>
          <input type="number" id="marginVertical" min="0" v-model="style.margin_v" class="light input">
        </div>
      </div>
    </div>
    <div class="style-form-bottom">
      <div class="preview">
        <div id="user-style-preview">
          {{ t('user.edit.user_styles.preview') }}
        </div>
      </div>
      <button @click="saveNewStyle()" class="authot ui green button">{{ t('user.edit.user_styles.save') }}</button>
    </div>
    <CWarningModal
      ref="warningUserStyle"
      modalId="userstyle"
      :modalHeader="t('authot_elements.modal.redirect_title')"
      :modalContent="t('authot_elements.modal.redirect_whithout_save')"
      :modalAbort="t('layout.shared.actions.cancel')"
      :modalValid="t('layout.shared.actions.valid')"
      @warning-modal-validated="closeStyleForm()"
    ></CWarningModal>
  </div>
</template>

<script>
import CWarningModal from '../authotElements/CWarningModal.vue';
import { EventBus } from '../../packs/application';
import AxiosStyleService from '../../services/userStyle/user_style';

export default {
  props: [
    "current_user",
    "isVisible"
  ],
  data() {
    return {
      FormModified: false,
      opaqueBox: "",
      userId: this.current_user,
      styleId: null,
      style: null
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    fontsList() {
      return this.$store.getters['userFonts/all_fonts']
    }
  },
  methods: {
    initDefaultStyle() {
      return {
        alignment:"2",
        angle:"0",
        background_color:"0",
        bold:"0",
        border_style:"1",
        encoding:"0 ",
        font_name:"Arial",
        font_size:"19",
        italic:"0",
        margin_l:"10",
        margin_r:"10",
        margin_v:"10",
        name_style:"",
        outline:"1",
        outline_color:"000000",
        primary_color:"ffffff",
        scale_x:"100",
        scale_y:"100",
        secondary_color:"ffffff",
        shadow:"0",
        spacing:"0",
        strike_out:"0",
        underline:"0",
        cpl: 40,
        cps: 20,
        full_count_cpl: 0,
        timecode_alert: 0
      }
    },
    initStyle(id, data, duplicate) {
      if(id == null && data == null) {
        this.styleId = id;
        this.style = this.initDefaultStyle();
      } else if(duplicate == true) {
        this.styleId = id;
        this.style = data;
        this.style.name_style = null;
      } else {
        this.styleId = id;
        this.style = data;
      }
      $('.ui.dropdown.font-dropdown').dropdown('set selected', this.style.font_name);
      this.FormModified = false;
    },
    initFontsList() {
      if(this.fontsList.length == 0) this.$store.dispatch('userFonts/getFonts', this.current_user);
    },
    manageChoosedFont(id) {
      this.$store.commit('userFonts/SET_REQUESTED_FONT', id);
      this.$store.commit('userFonts/ADD_FONT_FACE');
    },
    isFormModified() {
      this.FormModified && this.isVisible || this.styleId == null && this.isVisible ? this.$refs.warningUserStyle.openModal() : this.closeStyleForm();
    },
    closeStyleForm() {
      this.FormModified = false;
      this.style = this.initDefaultStyle();
      EventBus.$emit('back-to-styles');
    },
    primaryColorStyle() {
      if(this.style.primary_color == "0") {
        return null
      } else {
        return '#' + this.style.primary_color;
      }
    },
    outlineColorStyle() {
      if(this.style.outline_color == "0") {
        return null
      } else {
        return '#' + this.style.outline_color;
      }
    },
    backgroundColorStyle() {
      if(this.style.background_color == "0") {
        return null
      } else {
        return '#' + this.style.background_color
      }
    },
    getPrimaryColor(color) {
      if(color == null) {
        this.style.primary_color = "0";
      } else {
        let assColor = color.replace("#", "");
        this.style.primary_color = assColor;
      }
    },
    getOutlineColor(color) {
      if(color == null) {
        this.style.outline_color = "0";
      } else {
        let assColor = color.replace("#", "");
        this.style.outline_color = assColor;
      }
    },
    getShadowColor(color) {
      if(color == null) {
        this.style.background_color = "0";
      } else {
        let assColor = color.replace("#", "");
        this.style.background_color = assColor;
      }
    },
    saveNewStyle() {
      if(this.style.name_style == "" || this.style.name_style == null ) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.edit.user_styles.error_name_message'),
          class : 'ui toast authot',
        });
      } else if(this.style.name_style == "Default") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.edit.user_styles.error_default_name_message'),
          class : 'ui toast authot',
        });
      } else {
        this.styleId == null ? this.postNewStyle() : this.updateStyle();
      }
    },
    postNewStyle() {
      AxiosStyleService.postStyle(this.userId, this.style)
      .then(response => {
        this.FormModified = false;
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('user.edit.user_styles.style_saved'),
          class : 'ui toast authot',
        });
        this.styleId = response.data.id;
        this.style = this.initDefaultStyle();
        this.closeStyleForm();
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    },
    updateStyle() {
      AxiosStyleService.putStyle(this.userId, this.styleId, this.style)
      .then(response => {
        this.FormModified = false;
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('user.edit.user_styles.style_updated'),
          class : 'ui toast authot',
        });
        this.closeStyleForm();
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    }
  },
  watch: {
    style: {
      deep: true,
      handler() {
        EventBus.$emit("style-changed", this.style, "user-style-preview");
      }
    }
  },
  beforeMount() {
    this.style = this.initDefaultStyle();
  },
  mounted() {
    this.initFontsList();
    $('#myStyleTab').click(() => this.isFormModified());
    $('.styleInput').change(() => this.FormModified = true);
    EventBus.$on('open-style-form', (styleId, styleData, duplicate) => this.initStyle(styleId, styleData, duplicate));
    EventBus.$emit('style-changed', this.style, "user-style-preview");
    $('.ui.dropdown.font-dropdown').dropdown({
      allowAdditions: false,
      hideAdditions: true,
      clearable: false,
      forceSelection: true,
      value: this.style.font_name,
      showOnFocus: false,
      onChange: (value, text, $selectedItem) => {
        this.manageChoosedFont($selectedItem[0].dataset.id);
        this.style.font_name = value;
      },
    });
  },
  components: {
    CWarningModal
  }
}
</script>

<style scoped>
.style-checkboxes {
  flex-direction: column;
  align-items: start !important;
}
</style>