<template>
  <div>
    <div class="video-edit-topbar ui top attached">
      <div class="ui checkbox style-select-all">
        <input type="checkbox" @change="checkDisabledDrag()">
        <label>{{ t('editor.subtitles.select_subtitles') }}</label>
      </div>
      <div class="ui checkbox">
        <input type="checkbox" v-model="autoSubScroll">
        <label>{{ t('editor.subtitles.auto_scroll') }}</label>
      </div>
      <div class="ui checkbox">
        <input type="checkbox" v-model="autoFocus">
        <label>{{ t('editor.subtitles.focus') }}</label>
      </div>
      <div>
        <label>{{ t('editor.subtitles.select_style') }}</label>
        <select class="ui mini selection dropdown main-select-json-style-dropdown">
          <option v-for="(style, i) in jsonStyles" :key="i" :value="style.name_style">
            {{ style.name_style }}
          </option>
        </select>
        <button type="button" class="mini white ui button" @click="setStyle()">{{ t('layout.shared.actions.apply') }}</button>
      </div>
      <div>
        <button type="button" class="mini authot little ui button" id="save-subtitle-button" @click="saveSubtitle(false)">{{ t('layout.shared.actions.save') }}</button>
        <button type="button" class="mini authot little ui button" @click="launchExportModal(video_id, subtitle_id)">{{ t('layout.shared.actions.export') }}</button>
        <button type="button" class="mini dark_grey ui button" @click="launchIncrustModal()">{{ t('layout.shared.actions.incrust') }}</button>
        <button v-if="subtitle.state == 140" type="button" class="mini green ui button" id="valid-subtitle-button" @click="saveSubtitle(false, true)">{{ t('layout.shared.actions.valid') }}</button>
        <CModalExportSubtitles :video_id="video_id" :subtitle_id="subtitle_id"></CModalExportSubtitles>
        <CModalIncrustation :subtitles_list="subtitles_list" :video_title="video_title" :video_id="video_id"></CModalIncrustation>
      </div>
    </div>
    
    <div class="subtitle-editor">
      <draggable v-model="jsonSubtitles" handle=".handle">
        <div class="subtitle-editor-container" :id="'subtitle-container-' + index" v-for="(subtitle, index) in jsonSubtitles" :key="index" ref="subDiv">
          <div class="subtitle-editor-container-left">
            <p class="sub-index">{{ index + 1 }}</p>
            <i class="arrows alternate icon handle" style="cursor:grab;"></i> 
            <div class="ui checkbox style-select-one">
              <input type="checkbox" name="style-select-one" :id="index" @change="checkDisabledDrag()">
              <label></label>
            </div>
          </div>
          <div class="subtitle-editor-container-center">
            <div class="subtitle-editor-container-center-inputs">
              <input type="hidden" :value="index + 1" class="subPosition" disabled>
              
              <CTooltip :text="t('editor.tooltips.start_time')">
                <div class="ui mini input">
                  <input 
                    type="text" 
                    :class="`${jsonSubtitles[index-1] && jsonSubtitles[index-1].end_time >= (subtitle.start_time - 0.2) && getTimecodeWarning(subtitle.style_name) ? 'sub-alert' : ''}`"
                    :value="convertSecondsToTime(subtitle.start_time)"
                    @click="selectInputData($event)"
                    @focusout="manageTimeCode(subtitle, 'start', $event)"
                    @keyup="preventLettersInTc($event)"
                  >
                </div>
              </CTooltip>
              
              <CTooltip :text="t('editor.tooltips.end_time')">
                <div class="ui mini input">
                  <input 
                    type="text" 
                    :class="`${jsonSubtitles[index+1] && jsonSubtitles[index+1].start_time <= (subtitle.end_time + 0.2) && getTimecodeWarning(subtitle.style_name) ? 'sub-alert' : ''}`"
                    :value="convertSecondsToTime(subtitle.end_time)"
                    @click="selectInputData($event)"
                    @focusout="manageTimeCode(subtitle, 'end', $event)"
                    @keyup="preventLettersInTc($event)"
                  >
                </div>
              </CTooltip>
              
              <CTooltip :text="t('editor.tooltips.subtitle_duration')">
              <div class="ui mini input">
                <input 
                  type="text" 
                  :value="convertSecondsToTime(getIntervalBetweenTwoTime(+subtitle.start_time, +subtitle.end_time))" 
                  disabled>
              </div>
              </CTooltip>
              
              <CTooltip :text="t('editor.tooltips.cpl')">
                <div class="ui mini input">
                  <input
                    :class="getQuantityWarning('cpl', subtitle.style_name, getCPL(subtitle.text, subtitle.style_name))" 
                    :value="'CPL: ' + getCPL(subtitle.text, subtitle.style_name)" 
                    disabled>
                </div>
              </CTooltip>
              
              <CTooltip :text="t('editor.tooltips.cps')">
                <div class="ui mini input">
                  <input
                    :class="getQuantityWarning('cps', subtitle.style_name, getCPS(subtitle.start_time, subtitle.end_time, subtitle.text))" 
                    :value="'CPS: ' + getCPS(subtitle.start_time, subtitle.end_time, subtitle.text)" 
                    disabled>
                </div>
              </CTooltip>
              
              <label>Style</label>
              <div class="ui mini input">
                <select 
                  class="ui mini selection dropdown select-json-style-dropdown" 
                  :id="'style-dropdown-' + index"
                  v-model="subtitle.style_name"
                >
                  <option v-for="(style, i) in jsonStyles" :key="i" :value="style.name_style">
                    {{ style.name_style }}
                  </option>
                </select>
              </div>
            </div>
            <div class="subtitle-editor-container-center-text ui form ">
              <textarea 
                type="text"
                class="subText"
                :id="'subText' + index"
                @click="manageSubtitleClicked(subtitle, $event, index)"
                v-model="subtitle.text"
                rows="2">
              </textarea>
              <div class="subtitle-editor-container-center-text-styling_button">
                <button class="ui mini white button bold disabled">B</button>
                <button class="ui mini white button italic" @click="manageItalicTag(index)">I</button>
                <button class="ui mini white button underline disabled">U</button>
                <button class="ui mini white button linethrough disabled">A</button>
              </div>
            </div>
          </div>
          <div class="subtitle-editor-container-right">
            <i class="large link step forward icon" data-active="false" @click="sendSubtitleToPlay(index)"></i>
            <i class="orange large link plus square outline icon" @click="checkIfAddSubtitle(index)"></i>
            <i class="red large link trash alternate icon" @click="openDeleteSubModal(index)"></i>
          </div>
        </div>
        <div class="subtitle-editor-icon_top">
          <i 
            :class="topIconHovered ? 'big link caret square up icon' : 'big link caret square up outline icon'" 
            @click="goTopSubtitlesList()" 
            @mouseover="topIconHovered = true"
            @mouseout="topIconHovered = false">
          </i>
        </div>
      </draggable>
    </div>

    <div class="ui modal delete tiny" id="modal-delete-subtitle">
      <div class="header">
        <i class="large trash alternate icon"></i>
        {{ t('authot_elements.modal.delete_subtitle_title') }}
      </div>
      <div class="content">
        <p v-html="t('authot_elements.modal.delete_subtitle_description')"></p>
      </div>
      <div class="actions">
        <button class="dark_grey ui button" @click="closeDeleteSubModal()">{{ t('layout.shared.actions.cancel') }}</button>
        <button class="green authot ui button delete-subtitle-button" @click="removeSubtitle()">{{ t('layout.shared.actions.confirm') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import AxiosSubtitleService from "../../services/subtitle/subtitle-edit";
import CModalExportSubtitles from "./CModalExportSubtitles";
import CModalIncrustation from "./CModalIncrustation";
import CTooltip from "../authotElements/CTooltip";
import { EventBus } from '../../packs/application';

export default {
  props: [
    "json_subtitles",
    "json_styles",
    "user_styles",
    "video_id",
    "video_title",
    "subtitle",
    "subtitle_id",
    "subtitle_title",
    "subtitles_list"
  ],
  data() {
    return {
      video: {},
      jsonSubtitles: this.json_subtitles,
      jsonStyles: this.json_styles,
      jsonStylesToSend: null,
      jsonSubsToSave:[],
      autoSubScroll: true,
      autoFocus: false,
      subToDelete: null,
      topIconHovered: false,
      activeSubtitleId: 0
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    // MANAGE SUBTITLES
    loopSubtitlesForFormat() {
      for(let i = 0; i < this.jsonSubtitles.length; i++) {
        this.jsonSubtitles[i].text = this.formatToHtmlEscape(this.jsonSubtitles[i].text)
      }
    },
    formatToHtmlEscape(text) {
      let formattedText = text.replace(/\\N/gi,'\n').replace("&nbsp;", " ");
      return formattedText;
    },
    formatToAssEscape(text) {
      let formattedText = text.replace(/\n/gi,'\\N');
      return formattedText;
    },
    checkIfAddSubtitle(index) {
      this.checkEndTime(index) ? this.addSubtitle(index) : this.displayNewSubtitleError();
    },
    manageSubtitleClicked(subtitle) {
      this.video.currentTime = subtitle.start_time;
      EventBus.$emit('video-clicked');
    },
    addSubtitle(index) {
      this.jsonSubtitles.splice(index + 1, 0, {
        "position": null,
        "start_time": this.jsonSubtitles[index].end_time + 0.06,
        "end_time": this.getNewSubtitleEndTime(index + 1, this.jsonSubtitles[index].end_time + 0.06),
        "text": "",
        "style_name": this.jsonSubtitles[index].style_name
      });
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
      this.displayNewSubtitleStyle($('#subtitle-container-' + (index + 1)));   
    },
    addSubtitleWithdata(index, subtitle, endTime, subText) {
      this.jsonSubtitles.splice(index + 1, 0, {
        "position": null,
        "start_time": subtitle.end_time + 0.06,
        "end_time": endTime,
        "text": subText,
        "style_name": subtitle.style_name
      });
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
      this.displayNewSubtitleStyle($('#subtitle-container-' + (index + 1)));  
    },
    addFirstSubtitle(index) {
      this.jsonSubtitles.splice(index, 0, {
        "position": null,
        "start_time": this.jsonSubtitles[index].start_time - 0.5,
        "end_time": this.jsonSubtitles[index].start_time - 0.06,
        "text": "",
        "style_name": this.jsonSubtitles[index].style_name
      });
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
      this.displayNewSubtitleStyle($('#subtitle-container-' + (index)))
    },
    duplicateSubtitle(index) {
      this.jsonSubtitles.splice(index + 1, 0, {
        "position": null,
        "start_time": this.jsonSubtitles[index].start_time,
        "end_time": this.jsonSubtitles[index].end_time,
        "text": this.jsonSubtitles[index].text,
        "style_name": this.jsonSubtitles[index].style_name
      });
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
      this.displayNewSubtitleStyle($('#subtitle-container-' + (index + 1)))
    },
    divideSubOnCursor(index, isNewSub) {
      const subtitle = this.jsonSubtitles[index]
      const subTextArea = document.getElementById('subText' + index);
      const subText = subTextArea.value;
      const subTextLength = subText.length;
      const subTextCursor = subTextArea.selectionStart;

      if(isNewSub) {
        const initialEndTime = subtitle.end_time;
        const subDuration = subtitle.end_time - subtitle.start_time;
        const firstSubLength = subText.substr(0, subTextCursor).length;
        const firstSubPourcent = Math.round((100 * firstSubLength)/subTextLength);
        const secondSubText = subText.substr(subTextCursor, subTextLength).trim();
        subtitle.text = subText.substr(0, subTextCursor);
        subtitle.end_time = subtitle.start_time + ((firstSubPourcent * subDuration)/100);
        this.addSubtitleWithdata(index, subtitle, initialEndTime, secondSubText)
      } else {
        subtitle.text = subText.substr(0, subTextCursor);
        this.jsonSubtitles[index + 1].text = subText.substr(subTextCursor, subTextLength).trim() + " " + this.jsonSubtitles[index + 1].text;
        this.jsonSubtitles[index + 1].start_time = subtitle.end_time + 0.1;
        EventBus.$emit('subtitle-update', this.jsonSubtitles);
      }
    },
    mergeSubtitles(index) {
      const subtitle = this.jsonSubtitles[index];
      const nextSubtitle = this.jsonSubtitles[index + 1];
      subtitle.end_time = nextSubtitle.end_time;
      subtitle.text += " " + nextSubtitle.text;
      this.jsonSubtitles.splice(index + 1, 1);
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
    },
    manageItalicTag(subIndex) {
      let textArea = document.getElementById(`subText${subIndex}`);
      let selectedText = textArea.value.substring(textArea.selectionStart, textArea.selectionEnd)
      if(selectedText) selectedText.includes('<i>') && selectedText.includes('</i>') ? this.removeItalicTag(subIndex, selectedText) : this.addItalicTag(subIndex, selectedText)
    },
    addItalicTag(subIndex, selectedText) {
      this.jsonSubtitles[subIndex].text = this.jsonSubtitles[subIndex].text.replace(selectedText, `<i>${selectedText}</i>`)
    },
    removeItalicTag(subIndex, selectedText) {
      selectedText = selectedText.replace('<i>', '')
      selectedText = selectedText.replace('</i>', '')
      this.jsonSubtitles[subIndex].text = this.jsonSubtitles[subIndex].text.replace(`<i>${selectedText}</i>`, selectedText)
    },
    getNewSubtitleEndTime(index, startTime) {
      let subtitleEndTime = this.jsonSubtitles[index] ?
        this.jsonSubtitles[index].start_time - 0.06 :
        this.jsonSubtitles[index - 1].end_time + 1.06;
      subtitleEndTime = subtitleEndTime < startTime ? startTime + 1 : subtitleEndTime;
      return subtitleEndTime;
    },
    launchPlayerEndSubtitle(subId) {
      const durationToStart = this.jsonSubtitles[subId].end_time - 0.5;
      EventBus.$emit('play-part-subtitle', durationToStart);
    },
    launchPlayerBeginSubtitle(subId) {
      const durationToStart = this.jsonSubtitles[subId].start_time;
      EventBus.$emit('play-part-subtitle', durationToStart);
    },
    checkEndTime(index) {
      const endTime = this.jsonSubtitles[index] ?
        this.jsonSubtitles[index].start_time - 0.06 :
        this.jsonSubtitles[index - 1].end_time + 1.06;
      const endTimeIsValid = endTime > this.video.duration ? false : true;
      return endTimeIsValid;
    },
    displayNewSubtitleError() {
      $('body').toast({
        showIcon: 'times circle outline icon big red',
        message: this.t('editor.toasts.subtitle_duration_error'),
        class : 'ui toast authot',
      });
    },
    displayNewSubtitleStyle(subtitleContainer) {
      $(subtitleContainer).addClass('new-subtitle-style');
      setTimeout(() => {
        $(subtitleContainer).removeClass('new-subtitle-style');
      }, 800);
    },
    sendSubtitleToPlay(index) {
      EventBus.$emit('subtitle-to-play', index);
    },
    openDeleteSubModal(index) {
      this.subToDelete = index;
      $('#modal-delete-subtitle').modal({
        inverted: true,
        closable: false
      });
      $('#modal-delete-subtitle').modal('show');
    },
    closeDeleteSubModal() {
      this.subToDelete = null;
      $('#modal-delete-subtitle').modal('hide');
    },
    removeSubtitle() {
      this.jsonSubtitles.splice(this.subToDelete, 1);
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
      this.closeDeleteSubModal();
    },
    goTopSubtitlesList() {
      $('.subtitle-editor').scrollTop(0);
    },
    blockSaveSubButton(isBlocked) {
      isBlocked ? $("#save-subtitle-button").addClass('disabled') : $("#save-subtitle-button").removeClass('disabled')
    },
    blockValidSubButton(isBlocked) {
      isBlocked ? $("#valid-subtitle-button").addClass('disabled') : $("#valid-subtitle-button").removeClass('disabled')
    },
    autoSaveSubtitle() {
      this.launchSave = setInterval(this.saveSubtitle, 600000);
    },
    saveSubtitle(autosave, isValid) {
      this.blockSaveSubButton(true);
      this.blockValidSubButton(true)
      this.getStylesUsed();
      for(let i = 0; i < this.jsonSubtitles.length; i++) {
        this.jsonSubsToSave.push({
          position: i + 1,
          start_time: this.jsonSubtitles[i].start_time,
          end_time: this.jsonSubtitles[i].end_time,
          style_name: this.jsonSubtitles[i].style_name,
          text: document.getElementById('subText' + i).value
        })
      }

      const jsonUpdatedSubtitles = {
        styles: this.jsonStylesToSend,
        subtitles: this.jsonSubsToSave,
        // subtitles: this.jsonSubtitles
      };

      AxiosSubtitleService.putVideo(this.video_id, this.subtitle_id, jsonUpdatedSubtitles)
      .then(response => {
        if(autosave == false) {
          $('body').toast({
            showIcon: 'check circle outline icon big green',
            message: this.t('editor.toasts.subtitles_saved'),
            class : 'ui toast authot',
          });
        }
        if(isValid) this.validSubtitle()
        this.jsonStylesToSend = null;
        this.jsonSubsToSave = [];
        this.blockSaveSubButton(false);
        if(!isValid) this.blockValidSubButton(false);
      })
      .catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
        this.jsonStylesToSend = null;
        this.jsonSubsToSave = [];
        this.blockSaveSubButton(false);
        this.blockValidSubButton(false);
      })
    },
    validSubtitle() {
      AxiosSubtitleService.validSubtitle(this.video_id, this.subtitle_id)
      .then(response => {
      }).catch(error => {
        console.log("ERROR", error)
      })
    },
    getStylesUsed() {
      const stylesUsed = Array.from(new Set($('.select-json-style-dropdown').dropdown('get value')));
      this.jsonStylesToSend = stylesUsed.map(style => {
        return this.jsonStyles.find(js => {
          return js.name_style == style;
        })
      });
    },
    getTimecodeWarning(styleName) {
      const style = this.jsonStyles.find(style => style.name_style == styleName)
      // const timecodeWarning = style?.timecode_alert !== undefined ? style.timecode_alert : undefined
      const timecodeWarning = style ? style.timecode_alert : "false"
      return timecodeWarning == "true" ? true : false
    },
    getQuantityWarning(characterCalculType, styleName, characterQuantity) {
      const style = this.jsonStyles.find(style => style.name_style == styleName)
      if (style){
        if(!style.cpl && !style.cps) {
          style.cpl = 40
          style.cps = 20
        }
        if(characterCalculType == "cpl") {
          return characterQuantity > style.cpl ? "editor-input-warning" : characterQuantity <= style.cpl && characterQuantity >= style.cpl - 5 ? "editor-input-prevent" : "";
        } else {
          return characterQuantity > style.cps ? "editor-input-warning" : characterQuantity <= style.cps && characterQuantity >= style.cps - 5 ? "editor-input-prevent" : "";
        }
      }
    },
    setStyle() {
      const styleSelected = $('.main-select-json-style-dropdown').dropdown('get value');
      const checkboxesChecked = Array.from($('input[name="style-select-one"]:checked')).map(cb => cb.id);
      
      for(let i = 0; i < checkboxesChecked.length; i++) {
        this.$set(this.jsonSubtitles[checkboxesChecked[i]], "style_name", styleSelected);
      }
      if(checkboxesChecked.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('editor.toasts.apply_style_error'),
          class : 'ui toast authot',
        });
      } else {
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('editor.toasts.subtitles_style_updated'),
          class : 'ui toast authot',
        });
      }
    },
    checkDisabledDrag() {
      $('input[name="style-select-one"]:checked').length == 0 ?
        $('.handle').css('visibility', 'visible') :
        $('.handle').css('visibility', 'hidden');
    },

    // MANAGE EXPORT
    launchExportModal() {
      EventBus.$emit('open-export-modal');
    },

    // MANAGE INCRUSTATION
    launchIncrustModal() {
      EventBus.$emit('open-incrust-modal')
    },

    // MANAGE CPL/CPS/TC
    selectInputData(e) {
      e.target.select();
    },
    getCPL(text, styleName) {
      let isSpecialCaractersCounted = "false"
      const styleFound = this.jsonStyles.find(style => style.name_style == styleName);
      if(styleFound) isSpecialCaractersCounted = styleFound.full_count_cpl != undefined ? styleFound.full_count_cpl : "false";
      if(isSpecialCaractersCounted == "true") {
        const cpl = text.split(/\n/gi);
        return cpl.length == 1 ? cpl[0].length : cpl[0].length > cpl[1].length ? cpl[0].length : cpl[1].length;
      } else {
        let strings = text.split(/\n/gi)
        strings = strings.map(s => this.removeSpaceAndSpecialCharacters(s));
        return strings.length == 1 ? strings[0].length : strings[0].length > strings[1].length ? strings[0].length : strings[1].length;
      }
    },
    removeSpaceAndSpecialCharacters(stringToConvert) {
      stringToConvert = stringToConvert.replace(/[.,\/#!$?%\^&\*;:{}=\-_`~()]/g,"");
      stringToConvert = stringToConvert.replace(/\s/g,'');
      return stringToConvert;
    },
    getCPS(startTime, endTime, text) {
      let totalCharacters = text.replace(/[.,\/#!$?%\^&\*;:{}=\-_`~()]/g,"");
      totalCharacters = this.getNumberOfCharacters(totalCharacters);
      const timeInSeconds = this.getIntervalBetweenTwoTime(startTime, endTime);
      return Math.floor(this.getItemPerSecond(timeInSeconds, totalCharacters));
    },
    getNumberOfCharacters(string) {
      let numberOfCharacters = string.replace(/\s/g,'');
      numberOfCharacters = numberOfCharacters.replace(/\n/g,'');
      return numberOfCharacters.length
    },
    getIntervalBetweenTwoTime: (start, end) => end - start,
    getItemPerSecond: (durationInSecond, itemQuantity) => itemQuantity / durationInSecond,
    preventLettersInTc: (e) => e.srcElement.value = e.srcElement.value.replace(/[^0-9.,:]+/, ''),
    
    // MANAGE TIMECODE
    manageTimeCode(sub, timePosition, e) {
      const time = this.convertsTimeToSeconds(e.target.value);
      timePosition == 'start' ? sub.start_time = time : sub.end_time = time;
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
    },
    manageTimeCodeFromKey(subId, timePosition) {
      const time = this.video.currentTime;
      if(timePosition== 'start') {
        this.jsonSubtitles[subId].end_time < time ?
          this.jsonSubtitles[subId + 1].start_time = time :
          this.jsonSubtitles[subId].start_time = time;
      } else {
        this.jsonSubtitles[subId].start_time > time && this.jsonSubtitles[subId + 1].start_time > time ?
          this.jsonSubtitles[subId - 1].end_time = time :
          this.jsonSubtitles[subId].start_time > time && this.jsonSubtitles[subId + 1].start_time < time ?
          this.jsonSubtitles[subId + 1].end_time = time :
          this.jsonSubtitles[subId].end_time = time;
      }
      EventBus.$emit('subtitle-update', this.jsonSubtitles);
    },
    convertSecondsToTime(timeInSeconds, element) {
      const pad = (num, size) => ('000' + num).slice(size * -1);
      const time = parseFloat(timeInSeconds).toFixed(3);
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor(time / 60) % 60;
      const seconds = Math.floor(time - minutes * 60);
      const milliseconds = time.slice(-3);
      if(element === "video") {
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
      } else {
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
      }
    },
    convertsTimeToSeconds(TimeToConvert) {
      const splittedTime = TimeToConvert.split(':');
      const hoursInSeconds = +splittedTime[0] * 3600;
      const minutesInSeconds = +splittedTime[1] * 60;
      const seconds = +splittedTime[2].replace(',', '.');
      return hoursInSeconds + minutesInSeconds + seconds;
    },
    
    // Karaoke
    karaoke() {
      EventBus.$on('region-in', (subtitleId) => {
        this.manageSubKaraoke(subtitleId, "in");
        this.activeSubtitleId = subtitleId;
      });

      EventBus.$on('region-out', (subtitleId) => {
        this.manageSubKaraoke(subtitleId, "out");
      });
    },

    manageSubKaraoke(subtitleId, regionAction) {
      if(document.getElementById('subPlayDiv' + subtitleId)) document.getElementById('subPlayDiv' + subtitleId).remove();
      if(regionAction == 'in') {
        const subPlayContainer = document.getElementById('subPlayContainer');
        const subPlayDiv = document.createElement('div');
        subPlayContainer.appendChild(subPlayDiv);
        subPlayDiv.setAttribute('class', 'subtitlesPlayer');
        subPlayDiv.setAttribute('id', 'subPlayDiv' + subtitleId);
        subPlayDiv.setAttribute('data-index', subtitleId);
      }     
      const subtitles = this.$refs.subDiv;    
      const subToUnactive = subtitles.find(s => s.className == "subtitle-editor-container active");
      if(subToUnactive) subToUnactive.className = "subtitle-editor-container";
      regionAction == "in" ? this.getSubtitlesPlayed(subtitles[subtitleId], this.jsonSubtitles[subtitleId], document.getElementById('subPlayDiv' + subtitleId)) : subtitles[subtitleId].classList.remove("active");
      this.autoScroll(subtitleId);
    },

    // Add "Active" class to the subtitles played, display the subtitles into <span> on the video player
    getSubtitlesPlayed(subtitles, jsonSubtitle, subDivPlayed) {
      EventBus.$emit('subtitles-played', jsonSubtitle.style_name, subDivPlayed, true);
      subtitles.classList.add("active");
      const subtitlesText = this.splitStringOnLineBreak(jsonSubtitle.text);
      subDivPlayed.innerHTML = this.putStringOnSpan(subtitlesText);
    },
    
    // In subtitles text, split in multiple strings for each '\N'
    splitStringOnLineBreak(stringToSplit) {
      const stringsArray = stringToSplit.split("\n");
      return stringsArray;
    },

    putStringOnSpan(stringsArray) {
      let srtBloc = "";
      stringsArray.map(string => srtBloc += "<p class='sub-span'>" + string + "</p>");
      return srtBloc;
    },

    removeAllSubFromVideo() {
      const subDivsPlayed = Array.from(document.getElementsByClassName('subtitlesPlayer'));
      subDivsPlayed.map(sdp => sdp.remove());
    },

    autoScroll(subtitleId) {
      if(this.autoFocus) document.getElementById("subText" + subtitleId).focus();
      if(this.autoSubScroll) {
        if($('.subtitle-editor-container.active').length == 1) {
          const paddingToAdd = 25;
          const subtitleBoxHeight = document.getElementsByClassName('subtitle-editor-container')[0].offsetHeight + paddingToAdd;
          $('.subtitle-editor').scrollTop(document.getElementsByClassName('subtitle-editor-container active')[0].offsetTop - subtitleBoxHeight);
        }
      };
    },

    // Update sub time from the wave
    updateSubTime(subId, startTime, endTime) {
      const subtitle = this.jsonSubtitles.find((sub, i) => {
        return i == subId;
      });
      subtitle.start_time = startTime;
      subtitle.end_time = endTime;
    },

    // MANAGE KEYBOARD SHORTCUT
    manageAltKey(event, key) {
      switch(key) {
        case 13:
          // Divide on cursor in new subtitle : Alt + ENTER
          event.preventDefault();
          if(this.activeSubtitleId != null) this.divideSubOnCursor(this.activeSubtitleId, true);
          break;
        case 38:
          // Select previous subtitle : Alt + UP ARROW
          event.preventDefault();
          if(this.activeSubtitleId != 0)
            document.getElementById("subText" + (this.activeSubtitleId - 1)).focus(); 
            document.getElementById("subText" + (this.activeSubtitleId - 1)).click();
          break;
        case 40:
          // Select next subtitle : Alt + DOWN ARROW
          event.preventDefault();
          if(this.activeSubtitleId != null && this.activeSubtitleId !== (this.jsonSubtitles.length - 1))
            document.getElementById("subText" + (this.activeSubtitleId + 1)).focus();
            document.getElementById("subText" + (this.activeSubtitleId + 1)).click();
          break;
        case 68:
          // Define subtitle's start time : Alt + D
          event.preventDefault();
          if(this.activeSubtitleId != null) this.manageTimeCodeFromKey(this.activeSubtitleId, "start");
          break;
        case 70:
          // Define subtitle's end time : Alt + F
          event.preventDefault();
          if(this.activeSubtitleId != null) this.manageTimeCodeFromKey(this.activeSubtitleId, "end");
          break;
      }
    },
    manageCtrlKey(event, key) {
      switch(key) {
        // Open delete subtitle modal : Ctrl + BACKSPACE
        case 8:
          event.preventDefault();
          this.openDeleteSubModal(this.activeSubtitleId);
          break;
        case 68:
          // Duplicate subtitle : Ctrl + D
          event.preventDefault();
          if(this.activeSubtitleId != null) this.duplicateSubtitle(this.activeSubtitleId);
          break;
        case 70:
          // Read first 500ms of subtitle : Ctrl + F
          event.preventDefault();
          if(this.activeSubtitleId != null) this.launchPlayerBeginSubtitle(this.activeSubtitleId);
          break;
        case 73:
          // Add or remove italic tag from selected subtitles : Ctrl + I
          event.preventDefault();
          const subIndex = document.activeElement.id.replace('subText', '')
          this.manageItalicTag(this.activeSubtitleId);
          break;
        case 76:
          // Read last 500ms of subtitle : Ctrl + L
          event.preventDefault();
          if(this.activeSubtitleId != null) this.launchPlayerEndSubtitle(this.activeSubtitleId);
          break;
      }
    },
    manageShiftKey(event, key) {
      switch(key) {
        case 32: 
          // Play subtitle once : Shift + SPACE
          event.preventDefault();
          this.sendSubtitleToPlay(this.activeSubtitleId);
          break;
        case 37:
          // Go to subtitle's start time : Shift + LEFT ARROW
          event.preventDefault();
          if(this.activeSubtitleId != null) this.video.currentTime = this.jsonSubtitles[this.activeSubtitleId].start_time;
          break;
        case 39:
          // Go to subtitle's end time : Shift + RIGHT ARROW
          event.preventDefault();
          if(this.activeSubtitleId != null) this.video.currentTime = this.jsonSubtitles[this.activeSubtitleId].end_time;
          break;
      }
    },
    manageAltCtrlKey(event, key) {
      switch(key) {
        // Valid delete subtitle modal : Alt + Ctrl + BACKSPACE
        case 8:
          event.preventDefault();
          document.getElementsByClassName("delete-subtitle-button")[0].click();
          break;
        case 13:
          // Divide on cursor in next subtitle : Alt + Ctrl + ENTER
          event.preventDefault();
          if(this.activeSubtitleId != null) this.divideSubOnCursor(this.activeSubtitleId, false);
          break;
        case 66:
          // New subtitle (before) : Alt + Ctrl + B
          event.preventDefault();
          this.activeSubtitleId == 0 ?
            this.addFirstSubtitle(this.activeSubtitleId) :
            this.checkIfAddSubtitle(this.activeSubtitleId - 1);
          break;
        case 78:
          // New subtitle (after) : Alt + Ctrl + N
          event.preventDefault();
          if(this.activeSubtitleId != null) this.checkIfAddSubtitle(this.activeSubtitleId);
          break;
      }
    },
    manageCtrlShiftKey(event, key) {
      switch(key) {
        case 82:
          // Merge subtitles with the next one : Ctrl + Shift + R
          event.preventDefault();
          this.mergeSubtitles(this.activeSubtitleId);
          break;
        // Save : Ctrl + Shift + S
        case 83:
          event.preventDefault();
          this.saveSubtitle(false);
          break;
      }
    },
  },
  mounted() {
    this.video = document.getElementById("videoPlayed");
    this.autoSaveSubtitle();
    this.loopSubtitlesForFormat();
    this.karaoke();
    EventBus.$on('no-region-displayed', () => this.removeAllSubFromVideo());
    EventBus.$on('region-time-update', (id, start, end) => this.updateSubTime(id, start, end));
    EventBus.$on('region-click', (subtitleId) => this.manageSubKaraoke(subtitleId, "in"));
    EventBus.$on("remove-active-region", () => this.removeSubFromVideo());
    EventBus.$on('save-subtitles', () => this.saveSubtitle(true));
    $('.main-select-json-style-dropdown').dropdown({
      placeholder: 'Choose style'
    });
    // $('.select-json-style-dropdown').dropdown();
    $('.style-select-all').checkbox({
      onChecked: () => {
        $('.style-select-one').checkbox('check');
      },
      onUnchecked: () => {
        $('.style-select-one').checkbox('uncheck');
      },
    })
    $(document).keydown(event => {
      if(event.altKey == true && event.ctrlKey == true && event.shiftKey == false) {
        this.manageAltCtrlKey(event, event.keyCode);
        return
      } else if(event.altKey == true && event.ctrlKey == false && event.shiftKey == false) {
        this.manageAltKey(event, event.keyCode);
        return
      } else if(event.ctrlKey == true && event.altKey == false && event.shiftKey == false) {
        this.manageCtrlKey(event, event.keyCode);
        return
      } else if(event.shiftKey == true && event.altKey == false && event.ctrlKey == false) {
        this.manageShiftKey(event, event.keyCode);
        return
      } else if(event.shiftKey == true && event.altKey == false && event.ctrlKey == true) {
        this.manageCtrlShiftKey(event, event.keyCode);
        return
      };
    });
  },
  beforeDestroy() {
    clearInterval(this.launchSave);
  },
  components: {
    draggable,
    CModalExportSubtitles,
    CModalIncrustation,
    CTooltip
  },
}
</script>

<style scoped>
.active
  .subtitle-editor-container-center {
    background-color: rgba(246, 136, 7, 0.3);;
  }

.sub-alert {
  background-color: #EB5757 !important;
}
</style>