<template>
  <div class="tiny ui modal export-subtitles" id="export-subtitles-modal">
    <i class="close icon" @click="closeExportModal(video_id, subtitle_id)"></i>
    <div class="header">
      <i class="big download file icon"></i>
      <h3>{{ t('authot_elements.modal.export_subtitles_title') }}</h3>
    </div>
    <div class="content">
      <div class="export-subtitles-form">
        <h4>{{ t('authot_elements.modal.export_subtitles_description') }}</h4>
        <div class="formats-list">
          <div>
            <div class="ui checkbox format">
              <input type="checkbox" id="ass-export" name="format" value="ass">
              <label for="ass-export">{{ t('authot_elements.modal.export_label_ass') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="srt-export" name="format" value="srt">
              <label for="srt-export">{{ t('authot_elements.modal.export_label_srt') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="txt-export" name="format" value="txt">
              <label for="txt-export">{{ t('authot_elements.modal.export_label_txt') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="ttml-export" name="format" value="ttml">
              <label for="ttml-export">{{ t('authot_elements.modal.export_label_ttml') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="avid-export" name="format" value="avid">
              <label for="avid-export">{{ t('authot_elements.modal.export_label_avid') }}</label>
            </div>
          </div>
          <div>
            <div class="ui checkbox format">
              <input type="checkbox" id="vtt-export" name="format" value="vtt">
              <label for="vtt-export">{{ t('authot_elements.modal.export_label_vtt') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="webvtt-export" name="format" value="webvtt">
              <label for="webvtt-export">{{ t('authot_elements.modal.export_label_webvtt') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="vtt-vod-export" name="format" value="vtt_vod">
              <label for="vtt-vod-export">{{ t('authot_elements.modal.export_label_vtt-vod') }}</label>
            </div>
            <div class="ui checkbox format">
              <input type="checkbox" id="docx-export" name="format" value="docx">
              <label for="docx-export">{{ t('authot_elements.modal.export_label_docx') }}</label>
            </div>
          </div>
        </div>
        <div class="encode-format">
          <label for="">Format d'encodage</label>
          <div class="ui tiny encode selection dropdown">
            <input type="hidden" name="">
            <i class="dropdown icon"></i>
            <div class="default text"></div>
            <div class="menu">
              <div 
                class="item"
                v-for="(format) in encodeFormats" :key="format.key" 
                :data-value="format.key" 
                :title="format.format"
                name="encode"
              >
                {{ format.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="getMultiExport(video_id, subtitle_id)" class="authot ui button">{{ t('layout.shared.actions.export') }}</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';

export default {
  props: [
    "video_id",
    "subtitle_id",
  ],
  data() {
    return {
      selectedFormats: [],
      encodeFormats: [{name: 'UTF-8', key: 'utf-8'}, {name: 'UTF-8:BOM', key: 'utf-8:bom'}]
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    openExportModal() {
      $('#export-subtitles-modal').modal({
        inverted: true,
        closable: false
      });
      $('#export-subtitles-modal').modal('show');
    },

    convertArrayParams(aName, aValues) {
      let i, len, param, result;
      result = '';
      for (i = 0, len = aValues.length; i < len; i++) {
        param = aValues[i];
        result = result + aName + '[]=' + param + '&';
      }
      return result.slice(0, -1);
    },

    convertHashParams(aName, aHash) {
      console.log('HASH', aHash)
      return aName + '=' + encodeURIComponent(JSON.stringify(aHash));
    },

    getMultiExport(videoId, subtitleId) {
      let formats = document.querySelectorAll('input[name="format"]:checked');
      let encode = $('.ui.encode.selection.dropdown').dropdown('get value');
      console.log('encode test', encode)
      if(formats.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.export_error'),
          class : 'ui toast authot',
        });
      } else {
        EventBus.$emit("save-subtitles");
        formats.forEach(format => {
          this.selectedFormats.push(format.value);
        })
        console.log('url', `/video/${videoId}/subtitle/multi_export.zip?${this.convertArrayParams('subtitles_ids', [subtitleId])}&${this.convertArrayParams('formats_export', this.selectedFormats)}&${this.convertHashParams('options', { encode: encode, nb_chars: 40, lines: 2 })}`)
        window.location.href = `/video/${videoId}/subtitle/multi_export.zip?${this.convertArrayParams('subtitles_ids', [subtitleId])}&${this.convertArrayParams('formats_export', this.selectedFormats)}&${this.convertHashParams('options', { encode: encode, nb_chars: 40, lines: 2 })}`;
        this.closeExportModal()
      }
    },

    closeExportModal() {
      this.selectedFormats = [];
      let formats = document.querySelectorAll('input[name="format"]');
      formats.forEach(format => {
        format.checked = false
      })
      $('#export-subtitles-modal').modal('hide');
    },
  },
  mounted() {
    EventBus.$on('open-export-modal', () => this.openExportModal())
    $('.ui.encode.selection.dropdown').dropdown('set selected', 'utf-8')
  }
}
</script>

<style scoped>
.encode-format {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>